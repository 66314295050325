import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./termsCondition.scss"
import { withTrans } from "../../i18n/withTrans"
import { useTranslation } from "react-i18next"
import { printToLetter } from "../../helpers/numericToAlphabet"

function TermsCondition() {
  const { t } = useTranslation()
  return (
    <Layout className="term-condition">
      <SEO title={t("footer.text-toc")} />
      <div className="header-tnc">
        <div className="header-title">
          Syarat dan Ketentuan Penggunaan AsetKu
        </div>
        <div className="header-subtitle">
          Syarat dan Ketentuan Penggunaan ("Syarat dan Ketentuan") ini
          menjelaskan aturan dan ketentuan yang mengatur akses dan penggunaan
          situs web dan layanan yang disediakan oleh PT Pintar Inovasi Digital,
          bersama dengan afiliasi kami (secara bersama-sama disebut "AsetKu"
          atau "kami"). Dengan menggunakan situs web kami, Anda setuju untuk
          mematuhi semua ketentuan yang tercantum dalam Syarat dan Ketentuan
          ini.
        </div>
      </div>
      <div className="term-condition-content">
        {content.map((e, index) => (
          <div className="section">
            <tr>
              {index == 0 ? (
                <td>
                  <h5
                    className={"title"}
                    dangerouslySetInnerHTML={{ __html: e.title }}
                  />
                </td>
              ) : (
                <>
                  <td>
                    {index === 0 ? (
                      <></>
                    ) : (
                      <h5 className={index == 0 ? "title" : "subtitle"}>
                        {index}.
                      </h5>
                    )}
                  </td>
                  <td></td>
                  <td>
                    <h5
                      className={index == 0 ? "title" : "subtitle"}
                      dangerouslySetInnerHTML={{ __html: e.title }}
                    />
                  </td>
                </>
              )}
            </tr>
            {/* <tr>
              <td>
                {index === 0 ? (
                  <></>
                ) : (
                  <h5 className={index == 0 ? "title" : "subtitle"}>
                    {index}.
                  </h5>
                )}
              </td>
              <td></td>
              <td>
                <h5
                  className={index == 0 ? "title" : "subtitle"}
                  dangerouslySetInnerHTML={{ __html: e.title }}
                />
              </td>
            </tr> */}

            {typeof e.description === "object" ? (
              e.description.map(el => (
                <>
                  {typeof el === "object" ? (
                    el.isTitle ? (
                      <p dangerouslySetInnerHTML={{ __html: el.title }} />
                    ) : (
                      <tr>
                        <td>
                          <p> {el.sequence}.</p>
                        </td>
                        <td></td>
                        <td>
                          {el.description.length === 0 ? (
                            <p dangerouslySetInnerHTML={{ __html: el.title }} />
                          ) : (
                            <>
                              <p
                                dangerouslySetInnerHTML={{ __html: el.title }}
                              />
                              {el.description.map((subEl, idx) => (
                                <tr>
                                  <td>
                                    <p> {printToLetter(idx).toLowerCase()}.</p>
                                  </td>
                                  <td></td>
                                  <td>
                                    {typeof subEl === "object" ? (
                                      <>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: subEl.title,
                                          }}
                                        />
                                        {subEl.description.map((item, i) => (
                                          <tr>
                                            <td>{i + 1}.</td>
                                            <td></td>
                                            <td>
                                              <p
                                                dangerouslySetInnerHTML={{
                                                  __html: item,
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                      </>
                                    ) : (
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: subEl,
                                        }}
                                      />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  ) : (
                    <p dangerouslySetInnerHTML={{ __html: el }} />
                  )}
                </>
              ))
            ) : (
              <p dangerouslySetInnerHTML={{ __html: e.description }} />
            )}
          </div>
        ))}
      </div>
    </Layout>
  )
}

const content = [
  {
    title: "SYARAT DAN KETENTUAN PENGGUNAAN",
    description:
      "Pembaharuan Terakhir:<br/><br/>Selamat datang di ASETKU, suatu platform penyelenggara layanan pendanaan bersama berbasis teknologi informasi berbentuk mobile application atau aplikasi yang menghubungkan Pemberi Dana dan Penerima Dana sesuai dengan undang-undang dan peraturan hukum yang berlaku di Republik Indonesia.<br/>Setiap syarat dan ketentuan yang diatur dan ditetapkan dalam Ketentuan Penggunaan ini mengatur dan mengikat Pengguna. Dengan mengakses dan menggunakan layanan yang tersedia dalam ASETKU, maka Kami telah menerima pernyataan dan persetujuan Pengguna dan/atau Pengguna menyatakan telah membaca, memahami, dan menyetujui seluruh isi dan terikat dalam Ketentuan Penggunaan, termasuk juga setiap dokumen-dokumen yang ada di dalamnya dan/atau terkait padanya. Karena itu, Kami sangat menyarankan Pengguna untuk membaca secara seksama dan hati-hati atau mencetak Perjanjian sebagai referensi Pengguna pada saat ini atau masa mendatang (bilamana terjadi perubahan-perubahan yang dilakukan oleh Kami).<br/>APABILA PENGGUNA MERASA KEBERATAN DAN/ATAU TIDAK SETUJU ATAS SALAH SATU, SEBAGIAN, ATAU SELURUH ISI SYARAT DAN KETENTUAN PERJANJIAN MAKA BERHENTILAH MENGGUNAKAN DAN/ATAU MENGAKSES ASETKU DAN/ATAU LAYANAN-LAYANANNYA.<br/>Catatan: Bahwa hingga saat ini aplikasi seluler ASETKU hanya dapat diunduh melalui Google Playstore dan Appstore, dimana hal ini dapat berubah sewaktu waktu sesuai dengan pengembangan dan pengelolaan ASETKU yang akan diberitahukan dan disampaikan kepada Pengguna.",
  },
  {
    title: "Definisi",
    description: [
      {
        sequence: 1,
        title:
          "<strong>“Akun”</strong> berarti suatu identitas unik (selain Data Pribadi) yang dibuat Pengguna untuk mengakses dan menggunakan layanan yang tersedia dalam ASETKU sebagaimana syarat dan ketentuan yang diatur dalam Perjanjian, yang Kami gunakan untuk mencatat dan mengklasifikasikan kegiatan Pengguna dalam ASETKU",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "<strong>“ASETKU”</strong> berarti suatu sistem elektronik berbentuk mobile application atau aplikasi seluler dan situs (domain dan sub-domain) yang terkait dengannya, yang dikelola dan/atau dioperasikan oleh Kami guna menyediakan Layanan berdasarkan Perjanjian beserta setiap perubahannya yang menjadi satu kesatuan dan bagian yang tidak dapat dipisahkan.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "<strong>“Data Pribadi”</strong> berarti setiap informasi atau data yang diberikan oleh Pengguna dan dapat digunakan untuk mengidentifikasi secara langsung maupun tidak langsung setiap individu (yang merupakan orang perseorangan ) yang terdiri dari namun tidak terbatas pada username, email, password, nama lengkap, nomor telepon (termasuk nomor telepon Individu yang Relevan yang diwajibkan ASETKU), alamat, nomor induk kependudukan, nomor rekening, foto dan informasi lainnya, yang disampaikan Pengguna dalam menggunakan dan mengakses ASETKU. Penyelenggara hanya dapat mengakses, memperoleh, menyimpan, mengelola, memproses, dan/atau menggunakan Data Pribadi Pengguna setelah mendapatkan persetujuan dari Pengguna.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 4,
        title:
          "<strong>“Individu Yang Relevan”</strong> berarti anggota keluarga, teman, penerima manfaat, pengacara, pemegang saham, pemilik manfaat, kuasa, orang orang di bawah perwaliamanatan, wali amanat, penjamin Pengguna, penyedia jaminan lainnya dan individu-individu lain yang terkait dengan Pengguna.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 5,
        title:
          "<strong>“Kami”</strong> berarti PT. PINTAR INOVASI DIGITAL, termasuk namun tidak terbatas pada penerus dan penerima pengalihan hak dari PT. PINTAR INOVASI DIGITAL, yang memiliki hak dan kewenangan untuk mengelola dan mengoperasikan ASETKU.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 6,
        title:
          "<strong>“Penyelenggara”</strong> adalah suatu badan hukum Indonesia yang menyediakan, mengelola, dan mengoperasikan LPBBTI baik secara konvensional yang berizin dan diawasi oleh Otoritas Jasa Keuangan.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 7,
        title:
          "<strong>“Kebijakan Privasi”</strong> berarti Kebijakan Privasi yang berlaku dalam ASETKU.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 8,
        title:
          "<strong>“Konten”</strong> berarti setiap dan/atau seluruh konten yang dimuat dalam ASETKU, secara keseluruhan atau setiap bagian, terdiri tetapi tidak terbatas pada desain, teks, gambar grafis, foto, gambar, citra, video, perangkat lunak, musik, suara dan file lain, peringkat kredit, tarif, biaya, kuotasi, data historis, grafik, statistik, artikel, informasi kontak ASETKU, setiap informasi lain, dan pemilihan dan pengaturannya.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 9,
        title:
          "<strong>“Layanan”</strong> berarti layanan platform mobile application atau aplikasi seluler yang menyelenggarakan kegiatan pendanaan bersama berbasis teknologi informasi berbentuk pendanaan multiguna yang diselenggarakan Kami melalui ASETKU sebagaimana diatur lebih lanjut dalam Pasal 3 dibawah ini.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 10,
        title:
          "<strong>“Pengguna”</strong> berarti setiap individu (orang perseorangan ataupun setiap orang yang mewakili dan/atau atas nama bisnis, badan usaha atau badan hukum) yang mengakses dan/atau menggunakan ASETKU, baik yang telah membuat Akun ataupun belum.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 11,
        title:
          "<strong>“Pemberi Dana”</strong> berarti orang perseorangan, badan hukum, dan/atau badan usaha yang memberikan Pendanaan.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 12,
        title:
          "<strong>“Penerima Dana”</strong> berarti orang perseorangan, badan hukum, dan/atau badan usaha yang menerima Pendanaan.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 13,
        title:
          "<strong>“Perjanjian Pendanaan”</strong> berarti suatu dokumen dan/atau pernyataan (yang termasuk bagian dari Perjanjian) dimana Penerima Dana sepakat untuk meminjam dan Pemberi Dana sepakat untuk memberikan pinjaman, dan tindakan tersebut merupakan tindakan hukum yang mengikat secara hukum antara Penerima Dana dan Pemberi Dana. ASETKU berperan dalam Perjanjian Pendanaan sebagai perantara dalam bentuk penyelenggara layanan pendanaan bersama berbasis teknologi informasi",
        isTitle: false,
        description: [],
      },
      {
        sequence: 14,
        title:
          "<strong>“Perjanjian”</strong> berarti keseluruhan dokumen perjanjian pendanaan, yang terdiri dari:",
        isTitle: false,
        description: [
          "Ketentuan Penggunaan;",
          "Kebijakan Privasi;",
          "Perjanjian Pendanaan;",
          "Perjanjian lain antara ASETKU dengan Pengguna (apabila ada).",
        ],
      },
      {
        sequence: 15,
        title:
          "<strong>“Wilayah Operasi”</strong> berarti setiap wilayah di seluruh dunia, dimana ASETKU dapat diakses dan tetap merujuk pada ketentuan hukum Republik Indonesia.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Persyaratan Usia Pengguna",
    description: [
      {
        sequence: 1,
        title:
          "Untuk dapat mendapatkan akses, mendaftarkan diri, maupun menggunakan ASETKU, Pengguna wajib berusia 17 (tujuh belas) tahun atau lebih, dan merupakan pihak yang cakap dan mampu untuk mengikatkan dirinya dalam sebuah perjanjian yang sah menurut hukum dan peraturan perundang-undangan yang berlaku di Republik Indonesia, termasuk namun tidak terbatas pada Ketentuan Penggunaan, Kebijakan Privasi, dan dokumen-dokumen Perjanjian.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Pengguna wajib mengakses ASETKU, menggunakan setiap Layanan, dan mengikatkan diri Pengguna dalam Perjanjian atas nama:",
        isTitle: false,
        description: [
          "Pengguna sebagai pribadi berbentuk orang perseorangan; atau",
          "Entitas perusahaan dimana Pengguna adalah pihak yang berhak dan berwenang untuk mewakili dan melakukan tindakan hukum atas nama entitas yang diwakilinya ke dalam Perjanjian.",
        ],
      },
    ],
  },
  {
    title: "Layanan ASETKU",
    description: [
      {
        sequence: 1,
        title:
          "Pengguna memahami dan menyetujui bahwa melalui ASETKU, Kami menyediakan Layanan sebagaimana berikut ini, termasuk namun tidak terbatas pada:",
        isTitle: false,
        description: [
          "Menyediakan wadah bagi calon Penerima Dana untuk mencari pinjaman dan calon Pemberi Dana untuk memberikan pinjaman;",
          "Menyediakan ruang eksklusif dan mempertemukan antara calon Penerima Dana dan calon Pemberi Dana dalam ASETKU agar kesepakatan pendanaan dapat terlaksana;",
          "Menyeleksi, menganalisa dan menyetujui aplikasi pinjaman yang diajukan oleh calon Penerima Dana agar Pemberi Dana memberikan pinjaman yang berkualitas;",
        ],
      },
      {
        sequence: 2,
        title:
          "Pengguna memahami dan menyetujui bahwa saat ini Layanan ASETKU khusus disediakan untuk kegiatan pendanaan berbentuk multiguna dengan tujuan pemberian pendanaan oleh Pemberi Dana untuk Penerima Dana.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "Pengguna memahami dan menyetujui bahwa Kami dapat sewaktu-waktu memperluas ruang lingkup Layanan yang disediakan dalam ASETKU tanpa wajib memberitahukannya terlebih dahulu kepada Pengguna.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 4,
        title:
          "AsetKu hanya dapat mengakses kamera, lokasi, dan mikrofon pada gawai milik Anda.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 5,
        title:
          "Dalam menyediakan layanan-layanan dalam ASETKU, Kami dengan ini menegaskan bahwa:",
        isTitle: false,
        description: [
          "Kami tidak menjamin Penerima Dana akan mengembalikan pendanaan kepada Pemberi Dana sesuai dengan ketentuan dalam Perjanjian Pendanaan;",
          "Sebagai penyelenggara layanan pendanaan bersama berbasis teknologi informasi, ASETKU diatur dan diawasi oleh pemerintah melalui Otoritas Jasa Keuangan (OJK) sesuai dengan Peraturan OJK (POJK) NOMOR 10 /POJK.05/2022 tentang Layanan Pendanaan Bersama Berbasis Teknologi Informasi;",
          "Sesuai dengan ketentuan Otoritas Jasa Keuangan, ASETKU bertugas sebagai perantara administratif antara Pemberi Dana dan Penerima Dana;",
          "Pemberi Dana bertanggung jawab penuh atas risiko kredit maupun risiko gagal bayar yang mungkin timbul karena pemberian pendanaan yang dilakukan Pemberi Dana melalui ASETKU;",
          "Kami tidak dan tidak akan memberikan saran dan kewajiban kepada Pengguna selain dari apa yang telah ditegaskan dalam ASETKU termasuk dalam penyediaan Layanan melalui ASETKU, baik itu terdapat pada Ketentuan Penggunaan, Kebijakan Privasi, maupun Perjanjian Pendanaan;",
          "Kami tidak memberikan segala bentuk saran mengenai investasi, termasuk namun tidak terbatas pada rekomendasi investasi sehubungan dengan pilihan-pilihan yang ada dalam ASETKU dan difasilitasi oleh ASETKU kepada Pengguna;",
          "Kami tidak melakukan kegiatan, tindakan, maupun pemberian saran dan/atau konsultasi apapun yang menyebabkan Kami dan/atau ASETKU dianggap sebagai konsultan investasi atau manajer investasi sebagaimana dimaksud dalam ketentuan hukum dan perundang-undangan yang berlaku di Republik Indonesia;",
          "Konten, informasi, dan materi yang dimuat dalam ASETKU hanya dimaksudkan untuk memberikan informasi kepada Pengguna dalam bentuk <strong>“SEBAGAIMANA ADANYA”</strong> dan tidak dapat dianggap sebagai sebuah penawaran, permohonan, undangan, saran, konsultasi atau rekomendasi untuk melakukan pemberian pinjaman, pengajuan aplikasi pinjaman, melakukan kegiatan investasi, atau jasa keuangan lainnya",
          "Dana yang ditempatkan di dalam rekening Kami tidak akan dan tidak dapat dianggap sebagai simpanan yang diselenggarakan oleh perusahaan sebagaimana diatur dalam hukum dan peraturan perundang-undangan yang mengatur mengenai perbankan di Republik Indonesia;",
          "Kami akan berusaha untuk menjaga agar angka, harga, biaya dan nilai yang dimuat dan ditampilkan dalam ASETKU adalah seakurat mungkin dengan angka, harga, biaya dan nilai sebenarnya, namun ASETKU tidak dapat menjamin bahwa harga dan biaya yang ditampilkan dalam ASETKU mencerminkan perubahan angka, harga, biaya dan nilai (apabila ada) dengan segera;",
          "Seluruh informasi dan data yang didapatkan melalui ASETKU, termasuk namun tidak terbatas kepada Data Pribadi, akan disimpan oleh Kami sesuai dengan ketentuan Undang-Undang Nomor 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik beserta peraturan turunannya;",
          "Pengguna harus memahami transaksi dan isi perjanjian LPBBTI, termasuk batas atas fasilitas Pendanaan disesuaikan dengan kemampuan Pengguna dalam melakukan transaksi.",
          "Kegiatan usaha LPBBTI tunduk kepada seluruh ketentuan peraturan perundang-undangan yang terkait dengan LPBBTI.",
        ],
      },
    ],
  },
  {
    title: "Pernyataan dan Jaminan Pengguna",
    description: [
      {
        sequence: 0,
        title:
          "Pengguna dengan tanpa syarat dan tidak dapat ditarik kembali menyatakan dan menjamin bahwa:",
        isTitle: true,
        description: [],
      },
      {
        sequence: 1,
        title:
          "Pengguna telah membaca dan menyetujui Ketentuan Penggunaan dan Kebijakan Privasi yang berlaku dalam ASETKU;",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Pengguna memiliki hak hukum penuh, kapasitas, dan kewenangan secara hukum untuk mengakses, mendaftarkan diri, dan menggunakan ASETKU dan Layanan;",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "Pengguna memiliki itikad baik dan hanya akan mengakses, mendaftarkan diri, dan menggunakan ASETKU dan Layanan untuk tujuan yang sah berdasarkan hukum dan peraturan perundang-undangan yang berlaku di Republik Indonesia;",
        isTitle: false,
        description: [],
      },
      {
        sequence: 4,
        title:
          "Pengguna tidak melanggar kewajiban-kewajiban Pengguna dengan mengakses dan menggunakan ASETKU, termasuk namun tidak terbatas pada kewajiban Pengguna yang timbul berdasarkan perjanjian Pengguna dengan pihak ketiga lainnya;",
        isTitle: false,
        description: [],
      },
      {
        sequence: 5,
        title:
          "Pengguna tidak akan memuat, mengunggah, mempublikasikan maupun membagikan materi, informasi, maupun data apapun melalui ASETKU dan Layanan yang akan melanggar atau menyalahi hak-hak dari pihak ketiga manapun, termasuk terhadap hak cipta, merek dagang, dan hak kekayaan intelektual lainnya, serta mengandung fitnah, pencemaran nama baik, atau hal-hal lain yang melanggar hukum dan peraturan perundang-undangan yang berlaku;",
        isTitle: false,
        description: [],
      },
      {
        sequence: 6,
        title:
          "Seluruh data dan informasi yang disampaikan dan diberikan Pengguna melalui ASETKU kepada Kami, baik secara elektronik maupun manual, termasuk namun tidak terbatas pada Data Pribadi Pengguna, adalah akurat, lengkap dan tidak menyesatkan;",
        isTitle: false,
        description: [],
      },
      {
        sequence: 7,
        title:
          "Pengguna menjamin keaslian seluruh dokumen yang disampaikan. Atas setiap pemalsuan dokumen atau tindak pidana lain yang dilakukan oleh Pengguna, AsetKu dapat melakukan upaya hukum termasuk memproses tindakan yang dimaksud kepada pihak yang berwenang. Setiap pernyataan dan jaminan Pengguna di atas dibuat dengan sebenarbenarnya, tanpa menyembunyikan fakta dan hal material apapun, dengan demikian Pengguna wajib bertanggung jawab sepenuhnya atas kebenaran dari hal-hal yang dinyatakan di atas, dan wajib bertanggung jawab secara perdata maupun pidana, apabila pernyataan dan jaminan tersebut tidak sesuai dengan sebenarnya.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 8,
        title:
          "Setiap pernyataan dan jaminan Pengguna diatas dibuat dengan sebenar benarnya, tanpa menyembunyikan fakta dan hal material apapun, dengan demikian Pengguna wajib bertanggung jawab sepenuhnya atas kebenaran dari hal-hal yang dinyatakan di atas, dan wajib bertanggung jawab secara perdata maupun pidana, apabila pernyataan dan jaminan tersebut tidak sesuai dengan sebenarnya.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Kewajiban Pengguna",
    description: [
      {
        sequence: 1,
        title:
          "Dalam mengakses dan menggunakan ASETKU beserta Layanan, Pengguna wajib:",
        isTitle: false,
        description: [
          "Menggunakan ASETKU dan Layanan untuk tujuan dan sebab yang sah berdasarkan Perjanjian serta hukum dan peraturan perundang-undangan yang berlaku di Republik Indonesia;",
          "Tidak akan dan/atau tidak berniat untuk menguntungkan diri sendiri dengan cara-cara yang tidak wajar, rangkaian kebohongan membujuk orang lain, tipu muslihat atau menggunakan martabat palsu atau nama palsu, melakukan tindakan persengkongkolan dan/atau curang guna menguntungkan diri sendiri atau setidak-tidaknya tindakan tersebut dapat menyebabkan atau menjadikan Pemberi Dana yang memberikan pinjaman kepada Pengguna, Penerima Dana yang mendapatkan pinjaman dari Pengguna, Kami dan/atau ASETKU mengalami kerugian;",
          "Tidak menggunakan informasi dan data yang Pengguna terima terkait ASETKU dan Layanan untuk tujuan lain selain yang telah ditentukan dalam Ketentuan Penggunaan ini, termasuk di dalam Perjanjian;",
          "Tidak mengakses tanpa wewenang, meretas (hacking), menghalangi, mengganggu, menon-aktifkan, membebani dengan berlebihan atau mengganggu performa atau penampilan yang layak dari ASETKU maupun Layanan, termasuk namun tidak terbatas pada melakukan serangan penolakan layanan (denial of services), spoofing, hacking, gangguan rekayasa terbalik, pemrograman ulang, manipulasi data, crawling/scraping, otomatisasi dalam transaksi, atau pemanfaatan setiap teknik framing untuk melampirkan setiap konten atau informasi kepemilikan lainnya;",
          "Tidak mengunggah maupun memuat materi dan data apapun yang mengandung virus, trojan horse, worm, time-bomb, keystroke logger, spyware, adware, atau kode komputer berbahaya lain atau yang serupa, file atau program yang dirancang untuk menginterupsi,mempengaruhi, merusak, atau membatasi fungsi perangkat lunak atau perangkat keras, atau peralatan telekomunikasi;",
          "Tidak menggunakan automatic script, melakukan praktik scraping (perambanan) terhadap ASETKU dengan tujuan untuk mendapatkan persetujuan atau informasi lain dari ASETKU, Layanan dan Konten ASETKU untuk tujuan apapun;",
          "Tidak meminta kata sandi (password), mengumpulkan alamat email atau Data Pribadi Pengguna maupun calon Pengguna lainnya, baik secara langsung, tidak langsung, melalui alat elektronik, atau manual, untuk tujuan apapun, termasuk namun tidak terbatas pada pengiriman email atau komunikasi, atau upaya-upaya lain yang tidak diminta atau tidak disetujui untuk mengadakan transaksi pinjaman, baik melalui ASETKU maupun di luar aplikasi dan Layanan, atau tujuan komersial lainnya, atau tujuan lain yang melanggar hukum.",
          "Tidak mengunggah, memuat, atau memastikan pengiriman iklan, permintaan, materi promosi, junk mail, spam, chain letters, pyramid schemes, yang tidak diminta atau tidak sah ke dalam ASETKU dan/atau Layanan;",
          "Tidak mengunggah, memuat, atau memasukkan konten apapun ke dalam ASETKU maupun dalam komunikasi dengan pihak lain, yang menurut penilaian Kami merupakan konten yang berbahaya, mengancam, memfitnah, melanggar hukum, menghina, menghasut, melecehkan, vulgar, cabul, mengandung penipuan, menyerang privasi atau hak-hak publisitas, kebencian, atau mendiskriminasi ras atau etnis atau yang mungkin ditolak berdasarkan setiap hukum dan peraturan perundang-undangan, yang termasuk Konten yang Dilarang berdasarkan Surat Edaran Menteri Komunikasi dan Informatika Republik Indonesia, melanggar hak kekayaan intelektual, atau yang dapat merugikan Pengguna lainnya atau ASETKU.",
          "Tidak mengunggah informasi apapun dari pihak ketiga (doxxing) ke dalam ASETKU, termasuk namun tidak terbatas pada Data Pribadi pihak ketiga seperti alamat, nomor telepon, alamat email, nomor kartu identitas, nomor rekening dan nomor kartu kredit;",
          "Tidak berpura-pura memiliki identitas yang bukan merupakan identitas diri Pengguna sebenarnya atau mengaku-ngaku memiliki identitas yang tidak sesuai dengan kondisi dan identitas Pengguna sebenarnya;",
          "Tidak melakukan manipulasi, pemalsuan informasi atau data, maupun berkedok sebagai pihak lain manapun, baik orang perseorangan maupun entitas perusahaan, yang Pengguna tidak memiliki hak maupun wewenang untuk bertindak untuk dan atas nama pihak lain tersebut;",
          "Tidak membuat identitas palsu untuk mendaftarkan diri, mengakses, dan menggunakan ASETKU;",
          "Menggunakan koneksi internet dan perangkat-perangkat yang aman dan terpercaya untuk menjaga keamanan dan kelancaran performa ASETKU;",
          "Mendaftar dan menggunakan 1 (satu) Akun;",
          "Tidak mengelakkan, atau mencoba mengelakkan, cara maupun mekanisme pengamanan apapun dari ASETKU;",
          "Menjaga keamanan Akun Pengguna, termasuk kata sandi unik (password) dan keterangan unik lainnya yang identik dengan Akun Pengguna, serta bertanggung jawab penuh atas penggunaan Akun Pengguna oleh pihak lain selain Pengguna untuk tujuan apapun;",
          "Memastikan bahwa Pengguna keluar dari Akun dalam ASETKU di akhir setiap sesi;",
          "Segera melaporkan kepada ASETKU dalam hal Pengguna mengetahui dan/atau mendapatkan kata sandi dan/atau Akun Pengguna telah digunakan pihak lain tanpa sepengetahuan maupun seizin Pengguna;",
          "Tidak mengizinkan atau mendorong pihak lain untuk melakukan hal yang dilarang dalam Ketentuan Penggunaan ini termasuk Perjanjian;",
          "Tidak mencocokkan Data Pribadi yang diunggah oleh Pengguna lainnya, atau berkaitan dengan Pengguna lainnya, dengan tujuan untuk mengidentifikasi Pengguna lain, termasuk namun tidak terbatas melakukan upaya-upaya apapun untuk mendapatkan Data Pribadi Pengguna lain tersebut;",
          "Tidak mengintimidasi, melecehkan, atau membahayakan pihak Kami dan/atau ASETKU, Pengguna lainnya, ataupun pihak ketiga lainnya, atau berupaya untuk melakukan intimidasi, pelecehan dan tindakan lain yang dapat membahayakan pihak-pihak tersebut.",
        ],
      },
      {
        sequence: 2,
        title:
          "Dalam melakukan pendaftaran Akun sebagai Pengguna ASETKU, Pengguna dilarang untuk:",
        isTitle: false,
        description: [
          "Mendaftar untuk lebih dari satu akun Pengguna;",
          "Mendaftar untuk satu akun Pengguna atas nama individu (orang perseorangan) selain dirinya sendiri;",
          "Mendaftar untuk satu akun Pengguna atas nama entitas perusahaan manapun dimana Pengguna tidak memiliki hak dan kewenangan secara hukum untuk mewakili maupun bertindak untuk dan atas nama entitas perusahaan tersebut;",
          "Mengoperasikan akun Pengguna atas nama atau untuk kepentingan pihak manapun yang tidak memenuhi syarat untuk mendaftar atau mengoperasikan akun Pengguna dengan nama mereka sendiri;",
          "Mendaftar untuk akun Pengguna atas nama kelompok, perkumpulan, atau entitas lainnya yang tidak diatur maupun dikenal sebagai badan hukum berdasarkan hukum dan peraturan perundang-undangan yang berlaku di Republik Indonesia.",
        ],
      },
      {
        sequence: 3,
        title:
          "Kami memiliki hak untuk melakukan tindakan yang dianggap perlu terhadap Pengguna sewaktu-waktu, dalam hal menurut Kami Pengguna telah gagal untuk mematuhi satu dan/atau lebih dari kewajiban Pengguna sebagaimana dimaksud dalam Ketentuan Penggunaan ini termasuk Perjanjian, termasuk namun tidak terbatas pada pennon-aktifan Akun Pengguna sementara, pencabutan dan/atau pemblokiran Akun Pengguna secara permanen, pemasukan Pengguna ke dalam daftar hitam (blacklist), penahanan dan/atau pembatalan pencairan dana Pengguna yang ada di dalam rekening Kami.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 4,
        title:
          "Dalam hal Penerima Dana gagal untuk melunasi Pendanaan dan/atau Kewajiban Finansial Penerima Dana maka atas atau Kewajiban Finansial Penerima Dana diberikan kepada Perusahaan dan Penerima Dana. Perusahaan akan melakukan usaha terbaiknya untuk melakukan upaya penagihan, pengajuan serta proses claim asuransi serta jaminan (jika ada), dan pemenuhan Pendanaan dan/atau Kewajiban Finansial Penerima Dana, dengan ketentuan bahwa atas setiap wanprestasi yang bersifat permanen terkait pemenuhan Pendanaan atau Kewajiban Finansial Penerima Dana, maka baik Perusahaan, karyawan, direksi, komisaris, pemegang saham, manajemen, afiliasi, kuasa dan perwakilannya ataupun pihak lain terkait daripadanya dibebaskan dari dan tidak dapat dilibatkan dalam setiap tanggung jawab, proses hukum dan kerugian, pengeluaran, dan ongkos maupun biaya apapun.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 5,
        title:
          "Tindakan sebagaimana dimaksud di atas tidak menghapus hak Kami untuk sewaktu-waktu menagih kompensasi, ganti rugi, dan pertanggungjawaban dari Pengguna, serta mengajukan upaya hukum terhadap Pengguna berdasarkan hukum dan peraturan perundang-undangan yang berlaku;",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Hubungan Kami dan/atau ASETKU dengan Pengguna",
    description: [
      {
        sequence: 1,
        title:
          "Pengguna memahami dan menyetujui bahwa tanggung jawab Kami hanyalah sebatas pada pihak yang menyediakan Layanan, bertindak sebagai perantara yang mempertemukan Pemberi Dana dengan Penerima Dana untuk melakukan kegiatan pendanaan melalui ASETKU.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Tidak ada ketentuan yang ada dalam Ketentuan Penggunaan ini termasuk Perjanjian yang akan membuat atau dianggap membuat adanya hubungan kemitraan dan/atau agen, atau hubungan pemberi kerja antara Pengguna dengan Kami.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Hak atas Kekayaan Intelektual",
    description: [
      {
        sequence: 1,
        title:
          "Pengguna memahami dan menyetujui bahwa hak cipta, merek, paten, serta seluruh hak atas kekayaan intelektual lainnya yang dimuat maupun berhubungan dengan ASETKU, Layanan dan Konten, adalah milik Kami dan tetap merupakan hak Kami selama penggunaan ASETKU oleh Pengguna.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Pengguna memahami dan menyetujui bahwa gambar grafis, logo, desain, header halaman, ikon, tulisan dan nama Layanan yang ditampilkan dalam ASETKU merupakan aset milik Kami dan secara tegas dilindungi oleh Kami atau pihak ketiga yang ditunjuk oleh Kami.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "Pengguna memahami dan menyetujui bahwa pemberian akses, pendaftaran, dan penggunaan kepada Pengguna tidak dapat dianggap sebagai pemberian, pengalihan, maupun serah terima atas hak atau lisensi kepada Pengguna untuk menggunakan hak atas kekayaan intelektual yang dimuat atau berhubungan dengan ASETKU dengan cara apapun. Setiap hak atau lisensi untuk menggunakan hak kekayaan intelektual apapun yang ada pada Kami dan/atau ASETKU wajib didahului dengan persetujuan tertulis dari Kami.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 4,
        title:
          "Penggunaan nama ASETKU dan/atau hak kekayaan intelektual milik Kami dalam bentuk apapun, termasuk namun tidak terbatas pada iklan, publisitas, atau sebagai hyperlink wajib didahului dengan persetujuan tertulis dari Kami.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 5,
        title:
          "Kami memberikan Pengguna lisensi terbatas untuk mengakses dan menggunakan ASETKU dan untuk mengunduh atau mencetak salinan dari bagian manapun dari konten ASETKU semata-mata untuk penggunaan Pengguna pribadi sehubungan dengan penggunaan Pengguna atas ASETKU atau Layanan, dengan ketentuan bahwa Pengguna memelihara semua hak cipta atau pemberitahuan kepemilikan lengkap lainnya. Status ASETKU (dan bahwa dari setiap kontributor yang diidentifikasi) sebagai penulis konten pada ASETKU harus selalu diakui. Namun, Pengguna tidak wajib mengubah salinan kertas atau digital dari setiap materi yang Pengguna telah cetak atau unduh dengan cara apapun, dan Pengguna tidak harus menggunakan setiap ilustrasi, foto, video atau audio secara berurutan atau gambar grafis secara terpisah dari teks apapun yang menyertainya.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 6,
        title:
          "Lisensi ini dapat Kami batalkan sewaktu-waktu tanpa kewajiban untuk memberitahukan alasannya kepada Pengguna. Penggunaan tidak sah atas hak kekayaan intelektual Kami dan/atau yang terkait dengan ASETKU juga dapat melanggar hukum yang berlaku termasuk tetapi tidak terbatas, hukum dan ketentuan peraturan perundang-undangan mengenai hak cipta dan merek, dan dapat mengakibatkan diajukannya tuntutan hukum terhadap Pengguna.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 7,
        title:
          "Apabila Pengguna menggunakan ASETKU dan hak kekayaan intelektual yang terkandung di dalamnya dengan melanggar Ketentuan Penggunaan ini termasuk Perjanjian, hak Pengguna untuk menggunakan ASETKU dan hak kekayaan intelektual yang terkandung di dalamnya akan diberhentikan segera dan Pengguna harus, menurut kebijakan Kami, mengembalikan atau memusnahkan setiap salinan materi yang Pengguna telah buat.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Akses ke ASETKU",
    description: [
      {
        sequence: 1,
        title:
          "Mobile application atau aplikasi seluler ASETKU dapat diakses tanpa biaya apapun.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Kami selalu memberikan upaya terbaik untuk menjaga ASETKU agar aman, nyaman, dan berfungsi dengan baik terus menerus, namun Kami tidak dapat menjamin bahwa akses dan operasional ASETKU dapat terus menerus berlangsung secara sempurna.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "Kami tidak bertanggung jawab apabila ASETKU dan Layanan menjadi tidak dapat tersedia pada waktu dan periode tertentu.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 4,
        title:
          "Pengguna bertanggung jawab penuh untuk melakukan seluruh pengurusan yang diperlukan bagi Pengguna untuk dapat mengakses ASETKU.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 5,
        title:
          "Pengguna juga bertanggung jawab penuh untuk memastikan setiap pihak lain yang mengakses ASETKU melalui koneksi internet dan/atau Akun Pengguna telah membaca, memahami, mengetahui, dan menyetujui Ketentuan Penggunaan, Kebijakan Privasi dan ketentuan lainnya.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 6,
        title:
          "ASETKU ditujukan untuk digunakan Pengguna-Pengguna yang berdomisili di dalam wilayah Republik Indonesia. Kami tidak menjamin ketersediaan konten maupun layanan-layanan melalui ASETKU cukup dan dapat tersedia di wilayah lainnya.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 7,
        title:
          "Kami memiliki hak untuk membatasi ketersediaan ASETKU untuk pihak pihak tertentu atau pada wilayah-wilayah tertentu tanpa kewajiban pemberitahuan sebelumnya.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 8,
        title:
          "Pengguna memahami dan menyetujui bahwa akses, pendaftaran, pemanfaatan dan penggunaan ASETKU dari luar wilayah Republik Indonesia merupakan risiko Pengguna sendiri.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Virus",
    description:
      "Kami tidak menjamin bahwa ASETKU bebas dari segala virus maupun kerusakan. Pengguna bertanggung jawab secara pribadi untuk melakukan seluruh konfigurasi teknologi informasi, program, dan platform Pengguna sebagaimana diperlukan dalam rangka mengakses ASETKU, termasuk namun tidak terbatas pada penggunaan perangkat lunak anti-virus milik Pengguna sendiri.",
  },
  {
    title: "Larangan Penyalahgunaan ASETKU oleh Pengguna",
    description: [
      {
        sequence: 1,
        title:
          "Pengguna dilarang menyalahgunakan ASETKU dikarenakan sebab apapun.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title: "Pengguna dilarang:",
        isTitle: false,
        description: [
          "melakukan atau mendukung tindak pidana, mengirimkan atau mendistribusikan virus termasuk Trojan horse, worm, logic bomb atau mengirimkan materi berbahaya lainnya pada ASETKU, teknologi berbahaya, melanggar kepercayaan atau dengan cara apapun yang bersifat ofensif atau melecehkan;",
          "memasuki setiap aspek dari ASETKU;",
          "merusak data;",
          "menyebabkan gangguan terhadap Pengguna lain;",
          "melanggar hak kesopanan pihak lain;",
          "mengirim iklan atau materi promosi yang tidak diminta;",
          "mencoba untuk mempengaruhi kinerja atau fungsi dari setiap fasilitas perangkat atau akses terhadap seluruh ASETKU.",
        ],
      },
      {
        sequence: 3,
        title:
          "Pengguna memahami dan menyetujui bahwa setiap pelanggaran ketentuan ini merupakan tindak pidana di bawah Undang-Undang Nomor 11 Tahun 2008 tentang Internet dan Transaksi Elektronik (ITE) beserta peraturan turunannya, dan pelanggaran oleh Pengguna akan diikuti dengan pelaporan atas pelanggaran tersebut oleh Kami kepada pihak penegak hukum yang berwenang.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Tautan-Tautan ke Platform Pihak Ketiga",
    description: [
      {
        sequence: 1,
        title:
          "ASETKU sewaktu-waktu dapat terhubung dengan platform lainnya. Hubungan tersebut berada diluar kendali dan tanggung jawab Kami. Kami tidak menjamin isi atau ketersediaan platform terkait yang tidak dioperasikan oleh ASETKU. Tautan-tautan pada ASETKU, tersedia hanya untuk kenyamanan Pengguna dan Kami, tidak mengindikasikan kepercayaan atau persetujuan atas platform terkait. Untuk itu, Pengguna sebaiknya selalu merujuk kepada syarat dan ketentuan yang ada pada platform terkait sebelum Pengguna menggunakan platform tersebut dan ajukanlah pertanyaan atau komentar Pengguna langsung kepada penyedia platform tersebut.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Pengguna tidak diizinkan (dan juga Pengguna tidak diizinkan membantu orang lain) untuk membuat tautan dari ASETKU ke platform manapun tanpa persetujuan tertulis, yang ASETKU dapat berikan atau tolak sesuai dengan kebijakan Kami. Pengguna tidak diizinkan untuk membuat tautan langsung (Hot Link) terhadap konten atau gambar tanpa izin tertulis dari Kami terlebih dulu.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "Pengguna memahami dan menyetujui bahwa Kami dan/atau ASETKU tidak dapat dimintakan pertanggungjawaban atas konten dari aplikasi maupun situs yang dioperasikan pihak ketiga yang terhubung dengan ASETKU melalui tautan-tautan, termasuk namun tidak terbatas pada kerusakan yang dialami Pengguna yang mungkin disebabkan oleh penelusuran tautan-tautan tersebut oleh Pengguna, baik yang berasal dari spyware, malware, worm, kesalahan atau kerusakan materi yang terkandung dalam aplikasi maupun situs yang dioperasikan pihak ketiga.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Perubahan Konten Aplikasi",
    description: [
      {
        sequence: 1,
        title:
          "Kami dapat mengubah dari waktu ke waktu ASETKU beserta konten yang dimuat di dalam ASETKU.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Pengguna memahami dan menyetujui bahwa konten yang dimuat dalam ASETKU dapat menjadi kadaluwarsa sewaktu-waktu dan Kami tidak memiliki tanggung jawab untuk memperbaharui konten tersebut.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "Kami tidak menjamin bahwa ASETKU, beserta konten yang dimuat di dalamnya, terbebas dari segala kesalahan dan kelalaian.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Akun dan Kata Sandi",
    description: [
      {
        sequence: 1,
        title:
          "Apabila Pengguna memilih sendiri atau diberikan kode unik, kata sandi atau informasi lainnya dalam kaitannya dengan prosedur keamanan ASETKU, Pengguna harus memperlakukan informasi tersebut dengan rahasia dan hati-hati.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Penggunadilarangmemberikaninformasidiatastersebutkepadapihak ketiga.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "Kami memiliki hak untuk mengnonaktifkan kode unik atau kata sandi apapun sewaktu-waktu tanpa kewajiban pemberitahuan sebelumnya, baik yang Pengguna pilih sendiri atau yang Kami buatkan, jika menurut pandangan Kami, Pengguna telah gagal untuk melaksanakan Ketentuan Penggunaan ini.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 4,
        title:
          "Apabila Pengguna mengetahui atau mencurigai adanya pihak lain yang mengetahui kode unik atau kata sandi Pengguna, Pengguna wajib untuk memberitahukan dan menghubungi Kami.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Pengunggahan Konten oleh Pengguna ke dalam ASETKU",
    description: [
      {
        sequence: 1,
        title:
          "Apabila terdapat fitur dalam ASETKU yang memperbolehkan Pengguna mengunggah konten ke dalam ASETKU, konten tersebut wajib tunduk sesuai dengan ketentuan dalam Ketentuan Penggunaan ini dan ketentuan ketentuan peraturan perundang-undangan yang berlaku. Pengguna menjamin bahwa apabila konten tersebut tidak sesuai dengan ketentuan yang disyaratkan maka Pengguna bertanggung jawab kepada Kami dan/atau ASETKU dan wajib mengganti rugi Kami dan/atau ASETKU.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Konten yang Pengguna unggah ke dalam ASETKU akan Kami anggap sebagai bukan rahasia dan tidak menjadi milik siapapun. Pengguna memiliki hak milik terhadap konten tersebut, namun Pengguna dengan ini memberikan Kami dan/atau ASETKU kuasa terbatas untuk menggunakan, menyimpan dan menyalin konten tersebut dan untuk mendistribusikannya kepada pihak lain.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "Kami memiliki hak untuk memberitahukan identitas Pengguna kepada pihak ketiga yang mengklaim bahwa konten yang diunggah oleh Pengguna pada ASETKU menyebabkan timbulnya pelanggaran hak kekayaan intelektual pihak tersebut atau hak privasi pihak lain tersebut.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 4,
        title:
          "Kami tidak bertanggung jawab atau wajib mengganti rugi pihak ketiga atas akurasi konten yang diunggah oleh Pengguna atau pihak lainnya dalam ASETKU.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 5,
        title:
          "Kami memiliki hak untuk menyingkirkan unggahan atau konten yang Pengguna unggah dalam ASETKU, jika menurut pandangan ASETKU unggahan atau konten tersebut tidak sesuai dengan Perjanjian, Ketentuan Penggunaan ini dan ketentuan-ketentuan peraturan perundang-undangan yang berlaku.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Konten yang Dilarang",
    description: [
      {
        sequence: 1,
        title:
          "Dalam mengakses dan menggunakan ASETKU, Pengguna dilarang mengunggah, memuat, menampilkan, maupun membagi konten yang memuat:",
        isTitle: false,
        description: [
          "Konten negatif pornografi, termasuk namun tidak terbatas pada gambar, sketsa, ilustrasi, foto, tulisan, suara, bunyi, gambar bergerak, animasi, kartun, percakapan, gerak tubuh,atau bentuk pesan lainnya yang memuat kecabulan atau eksploitasi seksual yang melanggar norma kesusilaan dalam masyarakat, maupun layanan pornografi, baik yang bersifat implisit maupun eksplisit;",
          "Konten perjudian, lotere, dan/atau taruhan;",
          {
            title:
              "Konten dengan materi kebencian, termasuk namun tidak terbatas pada:",
            description: [
              "Konten yang yang melecehkan, mendegradasi, mendorong diskriminasi, atau mengandung muatan kebencian terhadap individu atau kelompok individu atas dasar agama, jenis kelamin, orientasi seksual, ras, etnis, usia, atau cacat tubuh;",
              "Konten yang menganjurkan atau membenarkan kekerasan atau membuat ancaman bahaya terhadap individu atau kelompok;",
              "Konten yang memberikan layanan menghilangkan nyawa orang lain dan/atau penganiayaan dan/atau perundungan;",
              "Konten yang menghasut atau mempromosikan kebencian terhadap kelompok atau individu;",
            ],
          },
          {
            title: "Konten gambar grafis yang dapat menimbulkan kengerian:",
            description: [
              "Gambar atau grafis yang berdarah seperti pertumpahan darah dan kecelakaan mengerikan atau aneh;",
              "Gambar atau grafis yang memuat organ tubuh manusia, darah;",
              "Penyiksaan baik hewan maupun manusia.",
            ],
          },
          "Konten yang melanggar Hak Kekayaan Intelektual (HKI);",
          "Konten jasa peretasan (hacking dan/atau cracking) dan/atau menyediakan akses tanpa hak atau melawan hukum atas sistem elektronik;",
          "Konten penyediaan dan/atau akses terhadap narkoba, zat adiktif, dan psikotropika;",
          "Konten dengan materi ketidakjujuran, kecurangan atau menyesatkan orang lain antara lain iklan mistis atau takhayul, penipuan, layanan atau jasa pencucian uang, jasa pemalsuan dokumen (termasuk dokumen ijazah dan/atau sertifikat), dan skema piramida (termasuk pemasaran afiliasi atau money game);",
          "Konten perdagangan manusia (human trafficking) dan/atau organ manusia;",
          "Konten tentang rokok;",
          "Konten yang mencemarkan nama baik dan/atau dapat mencemarkan nama baik siapapun;",
          "Konten yang memiliki kecenderungan untuk menipu dan/atau menyesatkan pihak lain;",
          "Konten yang mendorong kegiatan dan perbuatan yang ilegal atau melanggar hukum dan peraturan perundang-undangan yang berlaku;",
          "Konten yang mengancam, menyalahgunakan, atau melanggar privasi pihak lain;",
          "Konten yang menyebabkan gangguan, ketidaknyamanan, kecemasan, kekhawatiran orang lain;",
          "Konten yang mengatasnamakan pihak lain tanpa persetujuan darinya;",
          "Konten yang mengatasnamakan ASETKU, tanpa persetujuan tertulis dari ASETKU.",
        ],
      },
      {
        sequence: 2,
        title:
          "Pengguna memahami dan menyetujui bahwa dirinya bertanggung jawab kepada Kami dan wajib mengganti kerugian Kami atas setiap kerusakan atau kerugian yang disebabkan yang timbul dari setiap pengunggahan maupun pembagian Konten yang Dilarang oleh Pengguna melalui ASETKU.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "Pengguna memahami dan menyetujui bahwa terhadap pengunggahan maupun pembagian Konten oleh Pengguna di ASETKU, Kami memiliki hak untuk:",
        isTitle: false,
        description: [
          "Meninjau, menghilangkan, dan/atau menghapus setiap Konten yang diunggah Pengguna tanpa diperlukan pemberitahuan terlebih dahulu kepada Pengguna;",
          "Mengungkapkan identitas Pengguna ketika diminta atas dasar alasan yang sah atau atas dasar pelanggaran hak kekayaan intelektual atau hak privasi pihak lain oleh Pengguna.",
        ],
      },
    ],
  },
  {
    title: "Hak Pihak Ketiga",
    description:
      "Kecuali sebagaimana diatur secara tegas dalam Ketentuan Penggunaan ini atau perjanjian lain antara ASETKU dan Pengguna atau berdasarkan peraturan perundang-undangan yang berlaku, orang atau entitas yang bukan suatu pihak pada Perjanjian tidak memiliki hak berdasarkan setiap hukum yang berlaku untuk menuntut pelaksanaan setiap ketentuan Perjanjian, terlepas apakah orang atau entitas tersebut telah diidentifikasi dengan namanya, sebagai anggota dari suatu kelas atau sebagaimana sesuai dengan suatu deskripsi tertentu.",
  },
  {
    title: "Pembatasan Tanggung Jawab dan Ganti Kerugian",
    description: [
      {
        sequence: 1,
        title:
          "Dalam menyediakan ASETKU, ASETKU berupaya dan berkomitmen untuk tetap tunduk pada peraturan perundang-undangan yang berlaku, termasuk namun tidak terbatas pada Undang-Undang Nomor 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik beserta peraturan turunannya dan Undang-Undang Nomor 8 Tahun 1999 tentang Perlindungan Konsumen.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Kami dan/atau ASETKU tidak bertanggung jawab atas segala kerugian yang timbul atas gagalnya pemenuhan atau kelalaian Pengguna untuk melaksanakan ketentuan-ketentuan dalam Ketentuan Penggunaan, Kebijakan Privasi dan Perjanjian Pendanaan antara Pengguna dengan pihak ketiga lainnya.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "Kami dan/atau ASETKU tidak bertanggungjawab atas hubungan hukum antara Pemberi Dana dan Penerima Dana dalam Perjanjian Pendanaan, termasuk namun tidak terbatas apabila terdapatnya kerugian Pengguna atau pihak ketiga lainnya yang timbul berdasarkan Perjanjian Pendanaan tersebut.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 4,
        title:
          "Kami dan/atau ASETKU tidak bertanggungjawab apabila setelah aplikasi Penerima Dana diterima Kami dan/atau ASETKU, namun ternyata di kemudian hari Penerima Dana gagal melaksanakan kewajibannya berdasarkan Perjanjian Pendanaan kepada Pemberi Dana.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 5,
        title:
          "Tanpa mengesampingkan ketentuan-ketentuan lainnya dalam Ketentuan Penggunaan, Perjanjian Pendanaan Dan Kebijakan Privasi, Kami dan/atau ASETKU tidak bertanggungjawab atas setiap kerugian yang timbul sehubungan dengan:",
        isTitle: false,
        description: [
          "Tidak tersedianya akses dari dan ke ASETKU atau Layanan dikarenakan alasan dan sebab apapun;",
          "Perubahan, modifikasi, penghapusan, penambahan, penghentian dari ASETKU dan layanan-layanannya, Ketentuan Penggunaan, Kebijakan Privasi dan Perjanjian Pendanaan;",
          "Kegagalan kinerja sistem, jaringan, server, koneksi ASETKU sehingga menyebabkan tidak tersedianya ASETKU dan Layanan, baik yang terjadi secara sengaja, atau tidak sengaja, atau akibat dari perbuatan pihak ketiga di luar kuasa ASETKU;",
          "Ketidaklayakan, kurangnya, ketidaksempurnaan, kesesuaian, ketidakakuratan, kesalahan, ketidaklengkapan dan kekeliruan dari ASETKU dan Layanan;",
          "Hilangnya keuntungan, peluang atau data yang timbul dari penggunaan Pengguna atas ASETKU dan Layanan;",
          "Dampak merugikan yang Pengguna alami akibat mengakses ASETKU dan layanan-layanannya, termasuk namun tidak terbatas pada hilangnya keuntungan, gangguan bisnis, peluang bisnis;",
          "Setiap kerugian yang timbul diakibatkan oleh Keadaan Memaksa;",
          "Setiap pelanggaran atas pengesampingan Ketentuan Penggunaan dan Kebijakan Privasi yang diwajibkan oleh peraturan perundang-undangan yang berlaku, perintah otoritas yang berwajib atau putusan pengadilan.",
        ],
      },
      {
        sequence: 6,
        title:
          "Anda setuju untuk mengganti kerugian dan melindungi Kami dari kerugian terhadap semua tuntutan, kewajiban (termasuk kewajiban berdasarkan undang-undang dan kewajiban kepada para pihak ketiga), biaya, pengeluaran, denda, ongkos (termasuk tetapi tidak terbatas pada biaya hukum atas dasar ganti rugi penuh), ganti rugi, keputusan dan/atau kerugian yang diderita atau ditimbulkan oleh Kami, termasuk keuntungan dan peluang (yang potensial atau sebenarnya) yang dapat diambil darinya atau hilang, yang disebabkan atau timbul karena:",
        isTitle: false,
        description: [
          "Pelanggaran Pengguna terhadap Ketentuan Penggunaan, Kebijakan Privasi, Perjanjian Pendanaan Dan peraturan perundang-undangan yang berlaku;",
          "Pelanggaran Pengguna terhadap pernyataan dan jaminan sebagaimana disebutkan diatas;",
          "Perbuatan dari pihak ketiga menggunakan akun Pengguna yang menyebabkan kerugian bagi ASETKU dan/atau pihak lainnya;",
          "Kealfaan dari Pengguna yang menyebabkan kerugian dari pihak ketiga.",
        ],
      },
    ],
  },
  {
    title: "Pengakhiran",
    description: [
      {
        sequence: 1,
        title:
          "ASETKU dapat setiap saat secara sepihak menonaktifkan dan menutup Akun Pengguna, termasuk untuk menghapus seluruh informasi, data dan konten Pengguna, baik untuk sementara atau permanen dengan tanpa pemberitahuan terlebih dahulu apabila menurut pertimbangan kami hal tersebut perlu dilakukan.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Pengakhiran sebagaimana dimaksud dalam Pasal ini tidak menghilangkan kewajiban Pengguna atas segala pembayaran ganti rugi yang wajib dibayarkan sebagaimana diatur dalam Ketentuan Penggunaan dan Kebijakan Privasi ini.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 3,
        title:
          "Sehubungan dengan pengakhiran, ASETKU dan Pengguna sepakat untuk mengesampingkan Pasal 1266 dan Pasal 1267 Kitab Undang - Undang Hukum Perdata.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Keterpisahan",
    description: [
      {
        sequence: 1,
        title:
          "Jika ada syarat atau ketentuan dalam Ketentuan Penggunaan ini secara keseluruhan atau sebagian dinyatakan sampai batas apapun sebagai tidak sah atau tidak dapat dilaksanakan berdasarkan undang-undang atau peraturan hukum, syarat atau ketentuan atau bagian itu hingga batas tersebut dianggap bukan merupakan bagian dari Ketentuan Penggunaan ini dan keberlakuan dari syarat dan ketentuan lainnya di dalam Ketentuan Penggunaan maupun keseluruhan Perjanjian tidak akan terpengaruh.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Kegagalan Kami dalam melaksanakan atau menjalankan setiap hak atau ketentuan dari Ketentuan Penggunaan ini bukan merupakan pengesampingan hak atau ketentuan tersebut keadaan tersebut atau setiap keadaan lainnya.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Keseluruhan Perjanjian",
    description: [
      {
        sequence: 1,
        title:
          "Ketentuan Penggunaan, Kebijakan Privasi, Perjanjian Pendanaan, dan Perjanjian merupakan keseluruhan perjanjian antara Pengguna dan Kami dan/atau ASETKU, dan menggantikan setiap dan semua perjanjian sebelumnya dan kontemporer antara Pengguna dan ASETKU. Setiap pengesampingan Ketentuan Penggunaan akan efektif jika secara tertulis dan ditandatangani oleh penandatangan resmi oleh Kami dan/atau ASETKU.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Pengguna mengakui bahwa, dalam memasuki Perjanjian ini, baik Pengguna maupun Kami dan/atau ASETKU tidak mengandalkan representasi apapun, melakukan atau janji yang diberikan oleh yang lain atau tersirat dari kata-kata apapun atau tertulis antara Pengguna dan Kami dan/atau ASETKU sebelum Perjanjian, kecuali secara tegas dinyatakan dalam Perjanjian.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Force Majeure atau Keadaan Memaksa",
    description: [
      {
        sequence: 1,
        title:
          "Yang dimaksud dengan Keadaan Memaksa adalah kejadian-kejadian di luar kekuasaan dan di luar kemampuan yang wajar dari ASETKU yang mengakibatkan terjadinya keterlambatan pemenuhan kewajiban di dalam Ketentuan Penggunaan ini, yang antara lain meliputi bencana alam seperti gempa bumi, angin taufan, banjir, letusan gunung berapi, epidemi, kebakaran, pemogokan massal, perang, huru-hara, revolusi, kekacauan yang disebabkan keadaan ekonomi, politik, sosial, pemberontakan, perubahan pemerintah secara inkonstitusional, perubahan peraturan perundang-undangan dan perubahan kebijakan Pemerintah di bidang ekonomi dan moneter yang secara langsung mempengaruhi ASETKU.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Kami dan/atau ASETKU tidak dapat dimintakan pertanggungjawaban bila terjadi keterlambatan pemenuhan kewajiban di dalam Perjanjian ini yang disebabkan oleh Keadaan Memaksa.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Pengguna setuju untuk tidak menuntut hak apapun sehubungan dengan tidak dapat terlaksananya kewajiban ASETKU di dalam Perjanjian ini yang disebabkan oleh terjadinya Keadaan Memaksa.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Pajak dan Biaya-Biaya Lainnya",
    description:
      "Pajak dan biaya-biaya lainnya yang timbul sehubungan dengan ASETKU akan menjadi beban dan tanggung jawab masing-masing Pihak sesuai peraturan yang berlaku.",
  },
  {
    title: "Pemberitahuan",
    description:
      "Setiap pemberitahuan dan notifikasi berdasarkan Perjanjian akan diberikan secara tertulis baik melalui surat, faksimili atau email kepada Pengguna di alamat atau alamat e-mail sesuai dengan yang diinformasikan oleh Pengguna.",
  },
  {
    title: "Tanda Tangan Elektronik Perjanjian Pendanaan",
    description:
      "Apabila diperlukan, maka penandatanganan Perjanjian Pendanaan beserta dokumen turunannya dapat dilaksanakan melalui tanda tangan elektronik yang memiliki kekuatan dan nilai yang sama dengan tanda tangan fisik diantara Kami, Pemberi Dana, dan Penerima Dana.",
  },
  {
    title: "Pengalihan",
    description:
      "Pengguna sepakat bahwa hak dan kewajiban Pengguna dalam Ketentuan Penggunaan ini tidak dapat dialihkan kepada pihak ketiga lainnya, tanpa persetujuan tertulis dari Kami dan/atau ASETKU terlebih dahulu.",
  },
  {
    title: "Hukum yang Berlaku dan Yurisdiksi",
    description: [
      {
        sequence: 1,
        title:
          "Ketentuan Penggunaan serta seluruh hal yang berhubungan dengan akses Pengguna ke dalam aplikasi dan layanan akan diatur oleh dan ditafsirkan sesuai dengan hukum Republik Indonesia.",
        isTitle: false,
        description: [],
      },
      {
        sequence: 2,
        title:
          "Pengguna setuju bahwa tindakan hukum apapun atau sengketa yang mungkin timbul dari, berhubungan dengan, atau berada dalam cara apapun berhubungan dengan situs dan/atau Perjanjian ini akan diselesaikan secara non-eksklusif dalam yurisdiksi pengadilan Republik Indonesia.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "Pertentangan",
    description: [
      {
        sequence: 1,
        title:
          "Pengguna memahami dan menyetujui bahwa apabila terdapat pertentangan antara Ketentuan Penggunaan ini dengan Kebijakan Privasi, Perjanjian Pendanaan, maupun perikatan-perikatan yang timbul terkait dengan ASETKU dan Layanan antara Pengguna dengan Kami dan/atau ASETKU, maka urutan keberlakuan dari Perjanjian adalah sebagai berikut:",
        isTitle: false,
        description: [
          "Ketentuan Penggunaan;",
          "Kebijakan Privasi;",
          "Perjanjian Pendanaan;",
          "Perikatan-Perikatan lainnya antara Kami dan/atau ASETKU dengan Pengguna, apabila ada.",
        ],
      },
      {
        sequence: 2,
        title:
          "Batal demi hukumnya salah satu dari dokumen-dokumen Perjanjian tersebut di atas tidak menyebabkan batal demi hukumnya dokumen Perjanjian lainnya.",
        isTitle: false,
        description: [],
      },
    ],
  },
  {
    title: "FAQ (Frequently Asked Questions)",
    description:
      "Kami menganjurkan dan mendorong Pengguna untuk menjelajahi ASETKU untuk mendapatkan informasi lebih lanjut mengenai Layanan yang disediakan Kami, termasuk mengenai prosedur dan risiko yang dapat dibebankan kepada Pengguna dalam menggunakan Layanan ASETKU.",
  },
  {
    title: "Perubahan dan Pembaharuan",
    description:
      "Ketentuan Penggunaan dapat diubah atau diperbaharui sewaktu-waktu tanpa ada pemberitahuan terlebih dahulu. Pengguna disarankan untuk selalu membaca dan memeriksa Ketentuan Penggunaan secara seksama dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan ASETKU dan Layanan, maka Pengguna dianggap menyetujui perubahan-perubahan dalam Ketentuan Penggunaan.",
  },
  {
    title: "Hubungi Kami",
    description:
      "Untuk menghubungi Kami, silahkan mengirimkan email ke <a className='link-text' target='_blank' href='mailto:cs@asetku.com'>cs@asetku.com</a>, Kami tidak mengenakan biaya apa pun kepada Pengguna atas pelayanan pengaduan.",
  },
]

export default withTrans(TermsCondition)
